import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../shared/services/templates.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit {
  loading = true;
  noTemplates = false;
  templates;

  constructor(private router: Router, private templateService: TemplateService) {
    this.templateService.selectedTemplateId$.next(null);
  }

  navigateToFirstTemplate(templates) {
    if (templates && templates.length > 0) {
      this.loading = true;
      this.noTemplates = false;
      this.router.navigate([`details/${templates[0].id}/view`], {});
    } else {
      this.loading = false;
      this.noTemplates = true;
    }
  }

  async ngOnInit() {
    this.templateService.templates$.subscribe(templates => {
      this.templates = templates;
      this.navigateToFirstTemplate(templates);
    });
  }
}
