export class User {
    constructor(
      public username: string = null,
      public userId: string = null,
      public userType: string = null,
      public userClass: string = null,
      public primaryOrg: string = null,
      public firstName: string = null,
      public lastSystemName: string = null,
      public brand: string = null,
      public locale: string = null,
      public email: string = null,
      public phone: string = null,
      public countryCode: string = null,
    ) {}
  }