import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale } from '../shared/model/locale.model';
import { TranslateService } from '@ngx-translate/core';
import { TemplateService } from '../shared/services/templates.service';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TemplateFormComponent implements OnInit {
  readonly EDIT = 'edit';
  readonly CREATE = 'create';

  APPPREFIX = 'prt';

  locales: Locale[];
  timezones: Timezone[];

  multiLocaleConfig = {
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
    defaultLocale: new Locale(),
  };

  namePlaceholder = '';
  nameLabel = '';
  userLocale = 'en_US';

  loadingRegistrations;

  @Input() templateForm: FormGroup;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get displayLabels() {
    return this.templateForm.get('displayLabels');
  }

  get displayLabel() {
    return this.templateForm.get('displayLabel');
  }

  get supportedLocales() {
    return this.templateForm.get('supportedLocales');
  }

  get defaultLocale() {
    return this.templateForm.get('defaultLocale');
  }

  private _mode: string;

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private templateService: TemplateService,
    private translateService: TranslateService,
    private ngxCookieService: NgxCookieService,
  ) {
    this.timezones = timezonesService.timezones;
    this.nameLabel = this.translateService.instant('template.template_name');
    this.namePlaceholder = this.translateService.instant('template.placeholder.template_name');
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
  }

  ngOnInit() {
    this.loadDynamicLists();

    this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;
      this.supportedLocales.patchValue([locale]);

      if (this.isCreateMode) {
        this.defaultLocale.patchValue(locale.localeName);
      }

      if (this.isEditMode) {
        this.templateService.template$.subscribe(async template => {
          if (template) {
            this.displayLabel.setValue(template.displayLabel);
            this.buildSupportedLocales();
          }
        });
        this.buildSupportedLocales();
      }
    });
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return this.supportedLocales.value.length > num - 1 && !this.supportedLocales.value.includes(locale);
  }

  handleSelectionChange() {
    setTimeout(() => {
      this.triggerValidation();
    }, 100);

    if (this.supportedLocales.value.length === 0) {
      this.supportedLocales.patchValue([...this.supportedLocales.value, this.multiLocaleConfig.defaultLocale]);
    }
  }

  buildSupportedLocales() {
    if (this.supportedLocales.value && this.displayLabels?.value && this.locales) {
      this.supportedLocales.patchValue([]);
      const keys = Object.keys(this.displayLabels.value);
      for (const key of keys) {
        const localeFound = this.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([...this.supportedLocales.value, localeFound]);
        }
      }
    }
  }

  triggerValidation() {
    this.displayLabels.updateValueAndValidity();
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
  }
}
