import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GlobalNavService, Space, Context } from 'ngx-global-nav';
import { AnalyticsService, CookieService as NgxCookieService } from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './shared/services/sidenav.service';
import { TemplateService } from './shared/services/templates.service';
import { Router } from '@angular/router';
import { NavService } from './shared/services/global-nav.service';
import {Template} from './shared/model/template';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  programs: any[];
  sidenavOpen = true;
  selectedTemplateId: string;
  isInit = true;
  orgList: Space[] = [];
  templates: Template[] = [];
  navUrl = '';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private apiEndpointsService: ApiEndpointsService,
    private globalNavService: GlobalNavService,
    private analytics: AnalyticsService,
    private authService: AuthService,
    private cookieService: NgxCookieService,
    private sidenavService: SidenavService,
    private templateService: TemplateService,
    private navService: NavService,
  ) {
    this.templateService.templates$.subscribe(templates => {
      if (templates && templates.length > 0) {
        this.templates = templates;
        this.templateService.selectTemplate(this.templates[0].id);
      }
    });
    this.templateService.selectedTemplate$.subscribe(template => {
      if (template) {
        this.selectedTemplateId = template.id;
      }
    });
    this.templateService.getTemplates();
    this.getNavUrl();
  }

    async getNavUrl() {
      this.navUrl = await this.navService.getNavUrl();
    }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe((result) => {
        document.title = result;
      })
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }

  selectTemplate(e) {
    this.templateService.selectTemplate(e);
    if (!this.router.isActive('/', true)) {
      this.router.navigate([`/`], {});
    }
  }
}
