<div ngModelGroup="app-template" id="templateForm" [formGroup]="templateForm">
    <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'template.displayLabel' | translate }}</mat-label>
            <input
                id="displayLabel"
                matInput
                required
                maxlength="80"
                placeholder="{{ 'template.create.placeholder.displayLabel' | translate }}"
                formControlName="displayLabel"
                data-automation="displayLabel"
                data-url-persist
            />
            <mat-error *ngIf="displayLabel.hasError('required')">
                {{ 'template.validation.required' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'template.supported_locales' | translate }}</mat-label>
            <mat-select
                formControlName="supportedLocales"
                data-automation="supportedLocales"
                placeholder="{{ 'template.create.placeholder.supported_locales' | translate }}"
                (selectionChange)="handleSelectionChange()"
                multiple
            >
                <mat-option
                    *ngFor="let locale of locales"
                    [value]="locale"
                    [disabled]="limitLocaleSelectList(5, locale)"
                >
                    {{ locale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'template.timezone' | translate }}</mat-label>
            <mat-select
                required
                formControlName="timezone"
                data-automation="timezone"
                placeholder="{{ 'template.create.placeholder.timezone_selector' | translate }}"
            >
                <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                    {{ timezone.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'template.default_locale' | translate }}</mat-label>
            <mat-select
                required
                formControlName="defaultLocale"
                data-automation="locale"
                placeholder="{{ 'template.create.placeholder.locale_selector' | translate }}"
            >
                <mat-option
                    *ngFor="let supportedLocale of templateForm.controls.supportedLocales.value"
                    [value]="supportedLocale.localeName"
                >
                    {{ supportedLocale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
