import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Template } from '../model/template';
@Injectable()
export class TemplateService {
  conflictError = new Subject<any[]>();
  readonly templates$ = new BehaviorSubject<Template[]>(null);
  readonly template$ = new BehaviorSubject<Template>(null);
  readonly selectedTemplateId$ = new BehaviorSubject<string>(null);
  readonly selectedTemplate$ = new BehaviorSubject<Template>(null);

  constructor(private apiService: ApiService) {}

  async getTemplates() {
    try {
      const templates = await this.apiService.get(`templates`);
      this.templates$.next(convertKeys.toCamel(templates));
    } catch (err) {
      console.log('Could not load templates.', err);
    }
  }

  async getTemplate(id: string) {
    let template: any = {};

    try {
      const response = await this.apiService.get(`templates/${id}`);

      if (response.status === 409) {
        const { data } = response;
        this.conflictError.next(response);
        template = data.template;
      } else {
        template = response;
      }

      const { PORTFOLIO_EXTERNAL_REFERENCE_ID: external_reference_id = '' } = template?.alternate_ids || {};

      delete template.alternate_ids;
      template.external_reference_id = external_reference_id;

      template = convertKeys.toCamel(template);
      this.template$.next(template);
      this.selectedTemplateId$.next(id);
      return template;
    } catch (err) {
      console.log('Could not load template.', err);
      throw err;
    }
  }

  selectTemplate(templateId: string) {
    if (this.templates$.value && this.templates$.value.length > 0) {
      const template = this.templates$.value.find(template => {
        return template.id === templateId;
      });
      this.selectedTemplate$.next(template);
    }
  }

  async refetchTemplates() {
    if (this.selectedTemplate$.value) {
      await this.getTemplate(this.selectedTemplate$.value.id);
    }
  }

  async createTemplate(template: Template) {
    try {
      const dto = convertKeys.toSnake<any>(template);
      const createdTemplate = await this.apiService.post('templates', dto);
      return convertKeys.toCamel<Template>(createdTemplate);
    } catch (err) {
      console.log(`Could not Create Template`, err);
      throw err;
    }
  }

  async deleteTemplate(templateId: string) {
    const deletedTemplate = await this.apiService.delete(`templates/${templateId}`);
    return convertKeys.toCamel<any>(deletedTemplate);
  }

  async updateTemplate(template: Template) {
    try {
      const id = template.id;
      const dto = convertKeys.toSnake<any>(template);
      const updatedTemplate = await this.apiService.put(`templates/${id}`, dto);
      return convertKeys.toCamel<any>(updatedTemplate);
    } catch (err) {
      console.log(`Could not Update Template`, err);
      throw err;
    }
  }
}
