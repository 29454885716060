import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TemplateService } from '../shared/services/templates.service';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Template } from '../shared/model/template';
import { Locale } from '../shared/model/locale.model';
import { ValidatorFunctions } from '../shared/validators/validator.functions';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your asset';
  REQUIRED = 'required';
  CONFLICT = 'There is a data conflict with this Template';

  templateId = '';
  subscriptions = [];
  isSubmitting = false;
  isLoadingAsset = false;

  currentSite = null;
  templateForm: FormGroup = new FormGroup({
    displayLabel: new FormControl('', Validators.required),
    supportedLocales: new FormControl([new Locale()], Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    timezone: new FormControl('America/New_York', Validators.required)
  });
  readonly mode = 'edit';
  readonly APPPREFIX = 'prt';
  private template: Template;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private templateService: TemplateService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private translateService: TranslateService,
    public dialog: MatDialog,
  ) {
    this.translateService.get('template.notification.updated_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('template.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('template.notification.not_created');
      this.REQUIRED = this.translateService.instant('template.validation.required');
      this.CONFLICT = this.translateService.instant('template.notification.conflict');
    });

    this.templateService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });
  }

  ngOnInit() {
    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        this.templateId = params.id;
        this.isLoadingAsset = true;
        this.templateService.selectedTemplateId$.next(this.templateId);
        const template = await this.templateService.getTemplate(params.id);
        this.isLoadingAsset = false;
      }
    });

    const templateSub = this.templateService.template$.subscribe(async template => {
      if (template) {
        this.template = template;
        this.templateForm.patchValue(template);
      }
    });

    this.subscriptions = [routeSub, templateSub];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  async handleSubmit() {
    if (!this.templateForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        this.isSubmitting = true;
        const response = await this.templateService.updateTemplate(this.templateForm.getRawValue());
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        this.messageService.setSuccess(this.SUCCESS);
        setTimeout(() => {
          this.templateService.refetchTemplates();
          // this.router.navigate([`details/${response.id}/view`], {});
          this.router.navigate([`details/${this.templateId}/view`], {});
        }, 2000);
      } catch (e) {
        console.log(e);
        let errorMessage = '';
        if (e.error && e.error.message) {
          errorMessage = e.error.message;
        } else {
          errorMessage = 'ERR_BAD_REQUEST';
        }
        if (errorMessage === 'ERR_BAD_REQUEST') {
          this.messageService.setError(this.BAD_REQUEST);
        } else {
          this.messageService.setError(this.NOT_CREATED);
        }
      }
      this.isSubmitting = false;
    }
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        templateId: this.templateId,
      },
    });
  }

  handleCancel() {
    this.router.navigate([`details/${this.templateId}/view`]);
  }
}
