import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';
import { TemplateService } from '../shared/services/templates.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  displayedColumns: string[] = ['display_label'];
  loading = true;
  noTemplates = false;
  dataSource: any[];
  selectedTemplateId = '';
  isInit = true;

  constructor(
    private router: Router,
    private templateService: TemplateService,
    private sidenavService: SidenavService,
  ) {
    this.templateService.selectedTemplate$.subscribe(async template => {
      if (template && template.id) {
        this.loading = true;
        await this.templateService.getTemplate(template.id);
        this.loading = false;
      }
    });

    this.templateService.selectedTemplateId$.subscribe(async templateId => {
      if (templateId) {
        this.selectedTemplateId = templateId;
      }
    });

    this.templateService.templates$.subscribe(templates => {
      if (templates) {
        this.dataSource = templates;
        this.noTemplates = templates.length === 0;
      } else {
        return;
      }
    });
  }

  onRowClicked(id) {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`details/${id}/view`], {});
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }
}
