<div class="app-template-view content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingTemplate">bookmarks</mat-icon>
                    <mat-spinner *ngIf="loadingTemplate" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="view-title" class="ellipsis" data-automation="name" title="{{ template.displayLabel }}">
                    <ng-container *ngIf="!loadingTemplate">{{ template.displayLabel }}</ng-container>
                    <ng-container *ngIf="loadingTemplate">{{ 'template.loading_template' | translate }}</ng-container>
                </h1>
                <p class="subtitle" data-automation="subtitle">{{ 'template.view.subtitle' | translate }}</p>
            </div>
        </div>
        <div class="right-column">
            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                data-automation="delete"
                [disabled]="this.templateId === ''"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'template.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleEdit()"
                data-automation="templateEdit"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text"> {{ 'template.button.edit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #view="ngForm" class="template-form">
        <div class="form-group">
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'template.displayLabel' | translate }}:</mat-label>
                <div class="field-value" data-automation="displayLabel">
                    {{ getDisplayLabel() | translate }}
                </div>
            </div>
            <div class="view-field-container">
                <mat-label class="field-label">{{ 'template.timezone' | translate }}:</mat-label>
                <div class="field-value" data-automation="timezone">
                    {{ getTimezoneDisplayLabel() }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'template.locale' | translate }}:</mat-label>
                <div class="field-value" data-automation="locale">
                    {{ getLocaleDisplayLabel() }}
                </div>
            </div>
        </div>
    </form>
</div>
