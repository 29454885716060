<table mat-table [dataSource]="dataSource" [ngClass]="{ stickyTableHeader: !noTemplates || !loading }">
    <!-- Name Column -->
    <ng-container matColumnDef="display_label">
        <th mat-header-cell *matHeaderCellDef>{{ 'template.list.table.heading.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            <div class="template-label ellipsis" title="{{ element.displayLabel || element.id }}">
                {{ element.displayLabel || element.id }}
            </div>
        </td>
    </ng-container>

    <!--  Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <ng-container [ngSwitch]="element.status">
                <div *ngSwitchCase="'ACTIVE'" class="status-icon status-icon-active" title="Active"></div>
                <div *ngSwitchCase="'INACTIVE'" class="status-icon status-icon-inactive" title="Inactive"></div>
                <div *ngSwitchCase="'RETIRED'" class="status-icon status-icon-retired" title="Inactive"></div>
            </ng-container>
        </td>
    </ng-container>

    <tr
        mat-row
        *matRowDef="let row; let isEven = even; columns: displayedColumns"
        class="template-list-row"
        [ngClass]="{ selectedTemplate: row.id === selectedTemplateId }"
        (click)="onRowClicked(row.id)"
    ></tr>
</table>

<ng-container *ngIf="!loading">
    <div *ngIf="noTemplates" class="list-empty">
        {{ 'template.list.none_configured' | translate }}
    </div>
</ng-container>
<div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
<div class="create-button-container">
    <button
        (click)="routeToCreatePage()"
        id="create-button"
        class="icon-btn x-btn-primary"
        color="accent"
    >
        <mat-icon>add</mat-icon>
        <span class="btn-text">{{ 'template.list.button.create_template' | translate }}</span>
    </button>
</div>
