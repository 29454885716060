import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TemplateService } from '../shared/services/templates.service';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale } from '../shared/model/locale.model';
import { Template } from './../shared/model/template';
import { CookieService } from '../shared/services/cookie.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  form: FormGroup;
  templateId = '';
  subscriptions = [];
  locales: Locale[];
  timezones: Timezone[];
  labels: Template[];
  templatessList: Template[] = [];
  loadingTemplate = false;

  template: Template = {
    id: '',
    displayLabel: '',
    locales: '',
    timezone: ''
  };

  CONFLICT = 'There is a data conflict with this Template';
  readonly mode = 'view';

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private cookieService: CookieService,
    private templateService: TemplateService,
    private router: Router,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.translateService.get('template.notification.updated_successfully').subscribe((result: string) => {
      this.CONFLICT = this.translateService.instant('template.notification.conflict');
    });
    this.timezones = timezonesService.timezones;
    this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
    });
    this.loadDynamicLists();

    this.templateService.templates$.subscribe(templates => {
      this.templatessList = templates;
    });
    this.templateService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });
  }

  ngOnInit() {
    this.form = new FormGroup({});
    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        this.templateId = params.id;
        this.loadingTemplate = true;
        this.templateService.selectedTemplateId$.next(this.templateId);
        const template = await this.templateService.getTemplate(params.id);
        if (this.templateService.selectedTemplate$.value.id !== template.templateId) {
          this.templateService.selectTemplate(template[0].id);
        }
        this.loadingTemplate = false;
      }
    });

    const templateSub = this.templateService.selectedTemplate$.subscribe(async template => {
      if (template) {
        this.template = template;
        this.loadingTemplate = false;
      }
    });
    this.subscriptions = [routeSub, templateSub];
  }

  get locale() {
    return this.cookieService.getLocale();
  }

  getDisplayLabel(): string {
    if (this.template && this.template.displayLabel) {
      return this.templatessList.find(label => label.displayLabel === this.template.displayLabel).displayLabel;
    }
    return '-';
  }

  getLocaleDisplayLabel(): string {
    if (this.locales && this.locales.length > 0 && this.template && this.template.locales) {
      return this.locales.find(locale => locale.localeName === this.template.locales).displayLabel;
    }
    return '-';
  }

  getTimezoneDisplayLabel(): string {
    if (this.timezones.length > 0 && this.template && this.template.timezone) {
      return this.timezones.find(timezone => timezone.timezoneName === this.template.timezone).displayLabel;
    }
    return '-';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  handleCancel() {
    this.router.navigate(['list']);
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        templateId: this.templateId,
      },
    });
  }

  handleEdit() {
    this.router.navigate([`details/${this.templateId}/edit`]);
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
  }
}
