import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TemplateService } from '../../shared/services/templates.service';

export interface TemplateData {
  templateId: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  dialogState = 'DEFAULT';

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) public data: TemplateData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  async handleDelete() {
    try {
      this.dialogState = 'DELETING';
      await this.templateService.deleteTemplate(this.data.templateId);
      this.dialogState = 'DELETE_SUCCESS';
      setTimeout(() => {
        this.dialogRef.close();
        this.router.navigate([`/`], {});
      }, 2000);
    } catch (e) {
      this.dialogState = 'DELETE_FAILED';
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }
  }
}
